import React, { useEffect, useState } from "react";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import { differenceWith, isEqual } from "lodash";
import styled from "styled-components";

import PrivateRoute from "~/components/Authentication/PrivateRoute";
import InnerContainer from "~/components/Containers/InnerContainer";
import OutterContainer from "~/components/Containers/OutterContainer";
import LoadingError from "~/components/Loaders/LoadingError";
import AddTimeblock from "~/components/Timeblocks/AddTimeBlock";
import DisplayTimeBlock from "~/components/Timeblocks/DisplayTimeBlock";
import availabilityService from "~/utils/api/v1/availabilityService";
import timeBlockService from "~/utils/api/v1/timeblockService";
import { GroupedTimeBlocks, TimeBlock } from "~/utils/interfaces/Timeblock";
import { groupTimeBlocks } from "~/utils/timeblocks/group";

const SAddCircleIcon = styled(AddCircleIcon)`
  margin-left: 15px;
  cursor: pointer;
`;

const TitleWrapper = styled.h1`
  margin: 20px 0px;
`;

interface IProps {
  id: string;
}

const Schedule = (props: IProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [activeTimeblocks, setActiveTimeblocks] = useState<GroupedTimeBlocks>({});
  const [rawActiveTimeblocks, setRawActiveTimeblocks] = useState<TimeBlock[]>([]);
  const [allTimeblocks, setAllTimeblocks] = useState<TimeBlock[]>([]);
  const [showAdd, setShowAdd] = useState<boolean>(false);

  const fetchTimeblocks = async (): Promise<void> => {
    setLoading(true);
    try {
      const [actTbReq, allTbReq] = await Promise.all([
        availabilityService.labserviceService.getTimeBlocks(props.id),
        timeBlockService.fetchAllTimeBlocks({ lab_service_id: props.id }),
      ]);
      const actTbs = actTbReq.data.data;
      const allTbs = allTbReq.data.data;

      setActiveTimeblocks(groupTimeBlocks(actTbs.timeblocks) as GroupedTimeBlocks);
      setRawActiveTimeblocks(actTbs.timeblocks);
      setAllTimeblocks(allTbs);
    } catch (err: any) {
      setError(err.response.data.error);
      console.log(err);
    }
    setLoading(false);
  };

  const updateActiveTimeBlocks = (newTimeblocks: TimeBlock[]) => {
    const oldTimeblocks = [...rawActiveTimeblocks];
    const timeblocks = oldTimeblocks.concat(newTimeblocks);
    setRawActiveTimeblocks(timeblocks);
    setActiveTimeblocks(groupTimeBlocks(timeblocks) as GroupedTimeBlocks);
  };

  const deleteActiveTimeBlocks = (deletedTimeblocks: TimeBlock[]): void => {
    const oldTimeblocks = [...rawActiveTimeblocks];
    const newTimeblocks = differenceWith(oldTimeblocks, deletedTimeblocks, isEqual);
    setRawActiveTimeblocks(newTimeblocks);
    setActiveTimeblocks(groupTimeBlocks(newTimeblocks) as GroupedTimeBlocks);
  };

  useEffect((): void => {
    fetchTimeblocks();
  }, []);

  return (
    <PrivateRoute>
      <h1>Horarios LabService</h1>
      <OutterContainer>
        <InnerContainer
          backgroundColor="white"
          padding="20px 40px"
        >
          <LoadingError
            loading={loading}
            error={error}
          />
          {!loading && !error && (
            <>
              <TitleWrapper>
                Horarios actuales
                {!showAdd && (
                  <SAddCircleIcon
                    onClick={() => {
                      setShowAdd(true);
                    }}
                  />
                )}
              </TitleWrapper>
              {showAdd && (
                <AddTimeblock
                  onCancel={() => {
                    setShowAdd(false);
                  }}
                  timeblocks={allTimeblocks}
                  currentTimeblocks={rawActiveTimeblocks}
                  updateTimeblocks={updateActiveTimeBlocks}
                  id={props.id}
                  type="labservice"
                />
              )}
              <DisplayTimeBlock
                timeblocks={activeTimeblocks}
                deleteBlocks={deleteActiveTimeBlocks}
                isEditable
                id={props.id}
                type="labservice"
              />
            </>
          )}
        </InnerContainer>
      </OutterContainer>
    </PrivateRoute>
  );
};

export default Schedule;
